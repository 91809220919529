

























































































































import { Vue, Component, Ref } from 'vue-property-decorator'
import PageTitle from '@/components/common/pageTitle.vue'
import DatePicker from '@/components/common/DatePicker.vue'
import InputSearch from '@/components/common/InputSearch.vue'
import SecurityText from '@/components/common/SecurityText/index.vue'
import { application, options } from '@/utils/options'
import { record, is, phoneNumber, validate } from '@/utils/helpers'
import { ElTable } from 'element-ui/types/table'

type SearchType = 'name' | 'identityNumber' | 'tel'

@Component({ components: { PageTitle, DatePicker, InputSearch, SecurityText } })
export default class VisitorsReview extends Vue {
  @Ref() table: ElTable

  searchType: SearchType = 'name'
  searchRules: { [K in SearchType]?: (v: string) => string | void } = {
    tel: v => {
      if (!validate.phoneNumber(v) && !validate.landlineNumber(v)) return '请输入正确的电话号码'
    },
    identityNumber: v => {
      if (!validate.identityNumber(v)) return '请输入正确的身份证号'
    }
  }
  filterData: AnyObj = {
    startTime: this.$moment().startOf('day').toISOString(),
    endTime: this.$moment().endOf('day').toISOString(),
    status: ''
  }
  pagination = { pageSize: 20, currentPage: 1 }
  applicationList: AnyObj[] = []
  applicationTotal = 0
  placeholder = '请输入访客姓名查询'
  loading = false

  get statusOptions() {
    return application.statuses
  }

  get searchTypeOptions() {
    return application.searchTypes
  }

  get params() {
    const params = record(this.filterData).filter(is.ava)
    const { status, tel } = params
    const { currentPage, pageSize } = this.pagination

    if (is.num(status)) {
      params.status = [status]
    } else {
      params.status = application.statuses.map(option => option.value)
    }
    if (is.str(tel) && validate.phoneNumber(tel)) {
      params.tel = phoneNumber.standardize(tel)
    }

    return { offset: (currentPage - 1) * pageSize, limit: pageSize, ...params }
  }

  created() {
    this.fetchApplicationList()
  }

  getStatus(value: number) {
    return options(this.statusOptions).find(value)
  }

  async fetchApplicationList() {
    this.loading = true
    const { data } = await this.$api.europa.searchVisitorApplications(this.params)
    this.loading = false

    if (data.code === 0) {
      this.applicationList = data.data
      this.applicationTotal = data.count
    }
  }

  setParams(data: AnyObj) {
    this.filterData = { ...this.filterData, ...data }
  }

  filter() {
    this.pagination.currentPage = 1
    this.fetchApplicationList()
  }

  dateChange([start, end]: [Date, Date]) {
    this.setParams({ startTime: start.toISOString(), endTime: end.toISOString() })
    this.filter()
  }

  selectSearchType(type: any) {
    this.searchTypeOptions.forEach(({ value }) => {
      delete this.filterData[value]
    })

    this.searchType = type
    switch (type) {
      case 'name':
        this.placeholder = '请输入访客姓名查询'
        break
      case 'identityNumber':
        this.placeholder = '请输入证件号查询'
        break
      case 'tel':
        this.placeholder = '请输入联系电话查询'
    }
  }

  searchInputChange(value: any) {
    if (value) {
      const validate = this.searchRules[this.searchType]
      const message = validate && validate(value)

      if (message) {
        return this.$message({ message, type: 'error' })
      }

      const fuzzyFields = ['name']

      this.setParams({ [this.searchType]: value, fuzzy: fuzzyFields.includes(this.searchType) })
    } else {
      this.setParams({ [this.searchType]: null, fuzzy: null })
    }

    this.filter()
  }

  async pageChange() {
    await this.fetchApplicationList()

    this.table.bodyWrapper.scrollTop = 0
  }

  async handleApplication(id: string, status = 1, reason?: string) {
    const { data } = await this.$api.europa.updateVisitorApplication(id, { status, reason })

    if (data.code === 0) {
      this.$message({
        message: `${status === 1 ? '审核通过' : '审核未通过'}`,
        type: 'success'
      })
      this.fetchApplicationList()
    }
  }

  async refuseApplication(id: string) {
    try {
      const result: any = await this.$prompt('请输入未通过原因', '未通过原因', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })

      this.handleApplication(id, 2, result.value)
    } catch {
      //
    }
  }
}
